import React, { useEffect, useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useAtom } from 'jotai';

import '../../lang/i18n';
import { useTranslation } from 'react-i18next';
import { questionnaireAtom } from '../../jotai_state';

import {Tabs, CustomButton} from 'shared-components';
import TaskCard from './components/TaskCard';
import Modal from './components/FeedbackModal';

import { useData } from '../../contexts/dataContext';
import taskIcon from 'shared-assets/icons/task.svg';
import checkmarkIconGreen from 'shared-assets/icons/green/checkmark.svg';
import {Questionnaire} from "../FeedbackResultPage/questionnaire_interface";

export default function TasksPage() {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const translation = useTranslation();
  const { t } = translation;

  const { tasks } = useData();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasFeedbackHistory, setHasFeedbackHistory] = useState(false);
  const [activeTaskId, setActiveTaskId] = useState(-1);
  const [activeTaskContent, setActiveTaskContent] = useState('');
  const [activeTaskTitle, setActiveTaskTitle] = useState('');
  const [questionnaire, setQuestionnaire] = useAtom(questionnaireAtom);
  const [questionnaires, setQuestionnaires] = useState<Array<Questionnaire>>([]);
  const [tasksCompleted, setTasksCompleted] = useState(0)
  const [tasksNotCompleted, setTasksNotCompleted] = useState(0)

  const onCloseModal = () => {
    setIsModalOpen(false);
  }

  const navigateToTaskInChat = (task) => {
    const { name, description, deadline, completed } = task;
    let chatMessage = `${t('auto-task-chat-message')}: ${name}\n\n### ${t('description')}:\n\n${description || ''}`;

    if (deadline) {
      chatMessage += `\n\n**${t('deadline')}**: ${new Date(deadline).toLocaleDateString('sv-SE')}`;
    }

    if (completed) {
      chatMessage += `\n\n**${t('completed')}**: ${t('yes')}`;
    }

    const encodedMessage = encodeURIComponent(chatMessage);
    return `/?message=${encodedMessage}`;
  };

  const navigateToFeedback = (taskId, content, title) => {

    setActiveTaskContent(content || '');
    setActiveTaskId(taskId);
    setActiveTaskTitle(title);
    if (hasFeedbackHistory || (questionnaire && questionnaire.structure.length > 0)) {
      if(isModalOpen) {
        setIsModalOpen(false);
        return "";
      }
      const safeContent = encodeURIComponent(content)
      return `/feedback/create?id=${taskId}&type=task&title=${title}&content=${safeContent}`;
    } else {
      setIsModalOpen(true);
      return "";
    }
  }

  const getFeedback = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `https://feedback-api.dazzle.zebrain.se/questionnaire`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }

      const results = await response.json();
      setQuestionnaires(results)
      setHasFeedbackHistory(results.length > 0);

    } catch (error) {
      setHasFeedbackHistory(false);
    }
  };

  useEffect(() => {
    getFeedback();
  }, []);

  useEffect(() => {
    if (tasks && tasks.length > 0) {
      let tCompleted = 0
      let tNotCompleted = 0
      for (const task of tasks) {
        if (task.completed) {
          tCompleted++
        } else {
          tNotCompleted++
        }
      }
      setTasksCompleted(tCompleted)
      setTasksNotCompleted(tNotCompleted)
    }
  }, [tasks])

  const getTaskQuestionnaireId = (taskId: string): string|null => {
    for (const q of questionnaires) {
      if (q.origin.id === taskId) {
        return q.id
      }
    }
    return null
  }


  const OngoingTasks = () => {
    if (tasksCompleted === 0) {
      return (
        <div className="mt-6 text-center">
          <img src={taskIcon} alt="tasks" className="h-8 mx-auto"/>
          <p className="mt-2">{t("no-ongoing-tasks")}</p>

          <CustomButton className="mt-8" onClick={() => navigate('/development-plan')}>
            {t("go-to-focus-area")}
          </CustomButton>

        </div>
      )
    }

    return (
      <div className="flex flex-col gap-2 justify-start w-full flex-grow min-h-0 h-full">
        <div>
          <h1 className="text-lg font-medium dark:text-white">
            {t('tasks-page-title')}
          </h1>
        </div>
        <div className="flex flex-col gap-2 pr-2">
          {tasks.filter(task => !task.completed).map((task, index) => {
            const taskQuestionnaireId = getTaskQuestionnaireId(task.id)
            return (
              <div key={`task-card-${index}`}>
                <TaskCard
                  title={task.name}
                  description={task.description || ''}
                  leftIcon={taskIcon}
                  icon={task.completed ? checkmarkIconGreen : undefined}
                  deadline={
                    task.deadline
                      ? `${t('deadline')}: ${new Date(task.deadline).toLocaleDateString('sv-SE')}`
                      : undefined
                  }
                  chatLink={navigateToTaskInChat(task)}
                  feedbackLink={navigateToFeedback(task.id, task.description || '', task.name)}
                  taskId={task.id}
                  questionnaireId={taskQuestionnaireId}
                />

              </div>
            )
          })}
        </div>
      </div>
    );
  };

  const CompletedTasks = () => {

    if (tasksCompleted === 0) {
      return (
        <div className="mt-6 text-center">
          <img src={taskIcon} alt="tasks" className="h-8 mx-auto"/>
          <p className="mt-2">{t("no-completed-tasks-yet")}</p>

        </div>
      )
    }
    return (
      <div className="flex flex-col gap-2 justify-start w-full flex-grow min-h-0 h-full">
        <div>
          <h1 className="text-lg font-medium dark:text-white">
            {t('completed-tasks')}
          </h1>
        </div>
        <div className="flex flex-col gap-2">
          {tasks.filter(task => task.completed).map((task, index) => {
            const taskQuestionnaireId = getTaskQuestionnaireId(task.id)
            return (
              <TaskCard
                key={`task-card-completed-${index}`}
                title={task.name}
                description={task.description || ''}
                leftIcon={taskIcon}
                icon={task.completed ? checkmarkIconGreen : undefined}
                deadline={
                  task.deadline
                    ? `${t('deadline')}: ${new Date(task.deadline).toLocaleDateString('sv-SE')}`
                    : undefined
                }
                chatLink={navigateToTaskInChat(task)}
                feedbackLink={navigateToFeedback(task.id, task.description || '', task.name)}
                taskId={task.id}
                questionnaireId={taskQuestionnaireId}
              />
            )}
          )}
        </div>
      </div>
    );
  };

  if (!tasks || !tasks.length) {
    return (
      <div className="w-full h-full flex justify-center mt-4 desktop:mt-6">
        <div className="flex flex-col gap-2 justify-start align-middle w-full desktop:w-151 m-4 mt-0">
          <div className="flex justify-center">
            <img src={taskIcon} alt="task icon" className="w-6 h-6 desktop:w-10 desktop:h-10" />
          </div>
          <p className="text-base font-normal text-center">
            {t('no-tasks')}
          </p>
          <CustomButton className="mt-8" onClick={() => navigate('/development-plan')}>
            {t("go-to-focus-area")}
          </CustomButton>
        </div>
      </div>
    );
  }

  return (
    <>
      <h1 className="text-2xl font-medium dark:text-white my-4 desktop:my-6">
        {t("tasks-nav-title")}
      </h1>
      <div className="flex flex-col flex-grow min-h-0">
        {
          tasksNotCompleted > 0 ? (
            <Tabs
              items={[
                {title: t('ongoing'), content: <OngoingTasks/>},
                {title: t('completed'), content: <CompletedTasks/>},
              ]}
            />
          ) : (
            <OngoingTasks/>
          )
        }
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={onCloseModal}
        onModalStartClick={() => {
          if (isModalOpen) {
            setIsModalOpen(false);
          }
          navigate(`/feedback/create?id=${activeTaskId}&type=task&content=${activeTaskContent}&title=${activeTaskTitle}`)
        }}
      />
    </>
  );
}